import { createAction, union } from '@ngrx/store';

import { AuthUser } from '../auth.service.interface';
import { UserResponse } from './auth.interface';
import { IToken } from 'libs/api/src/interfaces/token';
import { IUser } from 'libs/api/src/interfaces';
import { Company } from 'libs/api/src/interfaces/company';
import { HttpErrorResponse } from '@angular/common/http';

export const ApplyToken = createAction('[Auth] APPLY_TOKEN', (payload: IToken) => ({ payload }));
// export const GetUser = createAction('[Auth] GET_USER', (payload: string) => ({ payload }));

// export const GetUserSuccess = createAction('[Auth] GET_USER_SUCCESS', (payload: Partial<IUser>) => ({ payload }));

// export const GetUserFail = createAction('[Auth] GET_USER_FAIL', (payload: Error) => ({ payload }));

export const Login = createAction('[Auth] LOGIN', (payload: { authUser: AuthUser; redirectPath: string }) => ({
  payload,
}));

export const LoginSuccess = createAction(
  '[Auth] LOGIN_SUCCESS',
  (payload: { user: Partial<IUser>; redirectPath: string }) => ({ payload }),
);

export const CompleteLogin = createAction(
  '[Auth] COMPLETE_LOGIN',
  (payload: { jwt: string; redirectPath?: string }) => ({ payload }),
);

export const CompleteLoginFail = createAction('[Auth] COMPLETE_LOGIN_FAIL', (payload: Error) => ({ payload }));

export const SetInitialLoginState = createAction(
  '[Auth] SET_INITIAL_LOGIN_STATE',
  (payload: { token: IToken; user: Partial<IUser>; hasLicense: boolean; redirectAfterLogin: boolean; redirectPath?: string }) => ({
    payload,
  }),
);

export const LoginFail = createAction('[Auth] LOGIN_FAIL', (payload: HttpErrorResponse) => ({ payload }));

export const LoginWithToken = createAction('[Auth] LOGIN_WITH_TOKEN', (payload: string) => ({ payload }));

export const LoginWithTokenSuccess = createAction('[Auth] LOGIN_WITH_TOKEN_SUCCESS', (payload: any) => ({ payload }));

export const LoginWithTokenFail = createAction('[Auth] LOGIN_WITH_TOKEN_FAIL', (payload: Error) => ({ payload }));

export const LogoutWithToken = createAction('[Auth] LOGOUT_WITH_TOKEN', (dummy?: boolean) => ({ dummy }));

export const LogoutWithTokenSuccess = createAction('[Auth] LOGOUT_WITH_TOKEN_SUCCESS', (payload: any) => ({ payload }));

export const LogoutWithTokenFail = createAction('[Auth] LOGOUT_WITH_TOKEN_FAIL', (payload: Error) => ({ payload }));

export const ConfirmEmailUpdate = createAction('[Auth] CONFIRM_EMAIL_UPDATE', (dummy?: boolean) => ({ dummy }));

export const ConfirmEmailUpdateSuccess = createAction('[Auth] CONFIRM_EMAIL_UPDATE_SUCCESS', (dummy?: boolean) => ({
  dummy,
}));

export const ConfirmEmailUpdateFail = createAction('[Auth] CONFIRM_EMAIL_UPDATE_FAIL', (payload: Error) => ({
  payload,
}));

export const Register = createAction('[Auth] REGISTER', (payload: Partial<IUser>) => ({ payload }));

export const RegisterSuccess = createAction('[Auth] REGISTER_SUCCESS', (payload: UserResponse) => ({ payload }));

export const RegisterFail = createAction('[Auth] REGISTER_FAIL', (payload: HttpErrorResponse) => ({ payload }));

export const RegisterExistingUser = createAction('[Auth] REGISTER_EXISTING_USER', (payload: Partial<IUser>) => ({
  payload,
}));

export const RegisterExistingUserSuccess = createAction(
  '[Auth] REGISTER_EXISTING_USER_SUCCESS',
  (payload: UserResponse) => ({ payload }),
);

export const RegisterExistingUserFail = createAction(
  '[Auth] REGISTER_EXISTING_USER_FAIL',
  (payload: HttpErrorResponse) => ({ payload }),
);

export const ConfirmRegister = createAction('[Auth] CONFIRM_REGISTER', (companyId: string) => ({ companyId }));

export const ConfirmRegisterSuccess = createAction('[Auth] CONFIRM_REGISTER_SUCCESS', (payload: UserResponse) => ({
  payload,
}));

export const ConfirmRegisterFail = createAction('[Auth] CONFIRM_REGISTER_FAIL', (payload: Error) => ({ payload }));

export const RecoverPassword = createAction(
  '[Auth] RECOVER_PASSWORD',
  (payload: { newPassword: string; companyId: string }) => ({
    payload,
  }),
);

export const RecoverPasswordSuccess = createAction('[Auth] RECOVER_PASSWORD_SUCCESS', (payload: any) => ({ payload }));

export const RecoverPasswordFail = createAction('[Auth] RECOVER_PASSWORD_FAIL', (payload: Error) => ({ payload }));

export const RequestPasswordRecoveryEmail = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_EMAIL',
  (payload: { emailAddress: string; companyId: string }) => ({ payload }),
);

export const RequestPasswordRecoveryEmailSuccess = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS',
  (payload: any) => ({ payload }),
);

export const RequestPasswordRecoveryEmailFail = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_EMAIL_FAIL',
  (payload: HttpErrorResponse) => ({ payload }),
);

export const RequestPasswordRecoverySession = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_SESSION',
  (payload: { emailAddress: string; sessionId: string }) => ({ payload }),
);

export const RequestPasswordRecoverySessionSuccess = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_SESSION_SUCCESS',
  (payload: any) => ({ payload }),
);

export const RequestPasswordRecoverySessionFail = createAction(
  '[Auth] REQUEST_PASSWORD_RECOVERY_SESSION_FAIL',
  (payload: HttpErrorResponse) => ({ payload }),
);

export const Logout = createAction('[Auth] LOGOUT', (redirect = '') => ({ redirect }));

export const LogoutSuccess = createAction('[Auth] LOGOUT_SUCCESS', (redirect = '') => ({ redirect }));

// export const SetBrandingCompany = createAction('[Auth] SET_BRANDING_COMPANY_ID', (payload:{companyId:string}) => ({payload}));

// export const SetBrandingCompanySuccess = createAction(
//   '[Auth] SET_BRANDING_COMPANY_ID_SUCCESS',
//   (dummy = '') => ({ payload:dummy }),
// );

// export const SetActiveDomain = createAction(
//   '[Auth] SET_ACTIVE_DOMAIN',
//   (payload: { activeDomain: string; redirectPath?: string }) => ({ payload }),
// );

export const ChangeActiveDomain = createAction(
  '[Auth] CHANGE_ACTIVE_DOMAIN',
  (payload: { activeDomain: string; redirectPath?: string }) => ({ payload }),
);

export const ChangeActiveDomainSuccess = createAction(
  '[Auth] CHANGE_ACTIVE_DOMAIN_SUCCESS',
  (payload: { token: string; redirectPath?: string }) => ({ payload }),
);

export const ChangeActiveDomainFail = createAction('[Auth] CHANGE_ACTIVE_DOMAIN_FAIL', (payload: Error) => ({
  payload,
}));

export const RetrieveContextFromStorage = createAction('[Auth] RETRIEVE_CONTEXT_FROM_STORAGE', (dummy?: boolean) => ({
  dummy,
}));
export const RetrieveContextFromStorageSuccess = createAction(
  '[Auth] RETRIEVE_CONTEXT_FROM_STORAGE_SUCCESS',
  (dummy?: boolean) => ({ dummy }),
);
export const RetrieveContextFromStorageFail = createAction(
  '[Auth] RETRIEVE_CONTEXT_FROM_STORAGE_FAIL',
  (payload: Error) => ({ payload }),
);

// export const RetrieveActiveDomainFromStorageSuccess = createAction(
//   '[Auth] RETRIEVE_ACTIVE_DOMAIN_FROM_STORAGE_SUCCESS',
//   (payload: string) => ({ payload }),
// );
// export const RetrieveActiveDomainFromStorageFail = createAction(
//   '[Auth] RETRIEVE_ACTIVE_DOMAIN_FROM_STORAGE_FAIL',
//   (payload: Error) => ({ payload }),
// );

export const ResetRequestPasswordRecovery = createAction(
  '[Auth] RESET_REQUEST_PASSWORD_RECOVERY',
  (dummy?: boolean) => ({ dummy }),
);

export const GetBrandingCompanyInfo = createAction('[Auth] GET_BRANDING_COMPANY_INFO', (dummy?: boolean) => ({
  dummy,
}));

export const GetBrandingCompanyInfoSuccess = createAction(
  '[Auth] GET_BRANDING_COMPANY_INFO_SUCCESS',
  (payload: Company) => ({ payload }),
);

export const GetBrandingCompanyInfoFail = createAction('[Auth] GET_BRANDING_COMPANY_INFO_FAIL', (payload: Error) => ({
  payload,
}));

export const GetUserToken = createAction('[Auth] GET_USER_TOKEN', (dummy?: boolean) => ({ dummy }));

export const GetUserTokenSuccess = createAction('[Auth] GET_USER_TOKEN_SUCCESS', (payload: any) => ({ payload }));

export const GetUserTokenFail = createAction('[Auth] GET_USER_TOKEN_FAIL', (payload: HttpErrorResponse) => ({
  payload,
}));

export const CompanyDetailsChanged = createAction('[Auth] COMPANY_DETAILS_CHANGED', (companyDetails: any) => ({
  companyDetails,
}));

export const ValidateUserInviteToken = createAction(
  '[Auth] VALIDATE_USER_INVITE_TOKEN',
  (payload: { tokenId: string; companyId: string }) => payload,
);

export const ValidateUserInviteTokenSuccess = createAction(
  '[Auth] VALIDATE_USER_INVITE_TOKEN_SUCCESS',
  (payload: any) => ({ payload }),
);

export const ValidateUserInviteTokenFail = createAction(
  '[Auth] VALIDATE_USER_INVITE_TOKEN_FAIL',
  (payload: HttpErrorResponse) => ({ payload }),
);

const allActions = union({
  ApplyToken,
  // GetUser,
  // GetUserSuccess,
  // GetUserFail,
  Login,
  LoginSuccess,
  LoginFail,
  LoginWithToken,
  LoginWithTokenSuccess,
  LoginWithTokenFail,
  LogoutWithToken,
  LogoutWithTokenSuccess,
  LogoutWithTokenFail,
  ConfirmEmailUpdate,
  ConfirmEmailUpdateSuccess,
  ConfirmEmailUpdateFail,
  Register,
  RegisterSuccess,
  RegisterFail,
  RegisterExistingUser,
  RegisterExistingUserSuccess,
  RegisterExistingUserFail,
  ConfirmRegister,
  ConfirmRegisterSuccess,
  ConfirmRegisterFail,
  RecoverPassword,
  RecoverPasswordSuccess,
  RecoverPasswordFail,
  RequestPasswordRecoveryEmail,
  RequestPasswordRecoveryEmailSuccess,
  RequestPasswordRecoveryEmailFail,
  RequestPasswordRecoverySession,
  RequestPasswordRecoverySessionSuccess,
  RequestPasswordRecoverySessionFail,
  Logout,
  LogoutSuccess,
  // SetActiveDomain,
  ChangeActiveDomain,
  ChangeActiveDomainSuccess,
  ChangeActiveDomainFail,
  RetrieveContextFromStorage,
  RetrieveContextFromStorageSuccess,
  RetrieveContextFromStorageFail,
  // RetrieveActiveDomainFromStorageSuccess,
  // RetrieveActiveDomainFromStorageFail,
  ResetRequestPasswordRecovery,
  GetBrandingCompanyInfo,
  GetBrandingCompanyInfoSuccess,
  GetBrandingCompanyInfoFail,
  GetUserToken,
  GetUserTokenSuccess,
  GetUserTokenFail,
  CompanyDetailsChanged,
  ValidateUserInviteToken,
  ValidateUserInviteTokenSuccess,
  ValidateUserInviteTokenFail,
  SetInitialLoginState,
  CompleteLogin,
  CompleteLoginFail,
});
export type AuthAction = typeof allActions;
