import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from './local-storage-jwt.service';
import { PureAbility } from '@casl/ability';

@Injectable({
  providedIn: 'root',
})
export class RedirectUrlService {
  constructor(private storage: LocalStorageService, private router: Router, private actRoute: ActivatedRoute, private ability: PureAbility) {}

  clearContextAndGetRedirectUrlTree(currentUrl = '') {
    this.storage.removeItem(LocalStorageKeys.jwtToken);
    this.storage.removeItem(LocalStorageKeys.activeDomain);
    this.storage.removeItem(LocalStorageKeys.brandingCompany);
    this.ability.update([]);

    const redirectPath = currentUrl.split('?')[0].trim();
    let redirect = '';

    // only set a redirect to an admin page (as opposed to /auth/login, /auth/register etc...)
    if (redirectPath.startsWith('/admin')) {
      // remove the redirect param if there is one
      const currentParams = { ...(this.actRoute.snapshot.queryParamMap as any).params };
      delete currentParams.redirect;
      redirect = this.router.createUrlTree([redirectPath], { queryParams: currentParams }).toString();
      return this.router.createUrlTree(['/auth/login'], { queryParams: { redirect }, queryParamsHandling: 'merge' });
    }

    return this.router.createUrlTree(['/auth/login']);
  }
}
