import { createReducer, on, Action } from '@ngrx/store';
import {
  ApplyToken,
  // GetUser,
  // GetUserSuccess,
  // GetUserFail,
  Login,
  Register,
  RegisterSuccess,
  LoginSuccess,
  LoginWithTokenSuccess,
  LoginWithTokenFail,
  ConfirmEmailUpdateSuccess,
  ConfirmEmailUpdateFail,
  ConfirmRegisterSuccess,
  ConfirmRegisterFail,
  RecoverPassword,
  RecoverPasswordSuccess,
  RecoverPasswordFail,
  LoginFail,
  RegisterFail,
  Logout,
  LogoutWithToken,
  RetrieveContextFromStorageSuccess,
  // RetrieveActiveDomainFromStorageSuccess,
  RequestPasswordRecoveryEmailSuccess,
  RequestPasswordRecoveryEmailFail,
  RequestPasswordRecoverySessionSuccess,
  RequestPasswordRecoverySessionFail,
  ResetRequestPasswordRecovery,
  GetBrandingCompanyInfoSuccess,
  CompanyDetailsChanged,
  ValidateUserInviteToken,
  ValidateUserInviteTokenSuccess,
  ValidateUserInviteTokenFail,
  //SetActiveDomain,
  SetInitialLoginState,
  CompleteLoginFail,
  CompleteLogin,
  RegisterExistingUser,
  RegisterExistingUserSuccess,
  RegisterExistingUserFail,
} from './auth.actions';
import { Auth, AuthStatus } from './auth.interface';
import { IToken } from '@zc/api';
import JwtDecode from 'jwt-decode';

export const initialState: Auth = {
  loggedIn: undefined, // set intial state to undefined as opposed to false. false means we've confirmed the user is not logged in.
  loginFailed: false,
  status: AuthStatus.INIT,
  user: {},
  roles: [],
  privileges: undefined,
  accessCompanies: undefined,
  activeDomain: '',
  error: undefined,
  userInviteToken: {}, // for some reason if this is initialized as undefined, changes won't be detected :(
};

export const authReducer = createReducer(
  initialState,
  on(ApplyToken, (state, action) => {
    return {
      ...state,
      roles: action.payload.roles,
      privileges: action.payload.privileges,
    };
  }),
  on(Login, (state, action) => {
    return { ...state, status: AuthStatus.IN_PROGRESS, user: { ...action.payload.authUser } };
  }),
  on(CompleteLoginFail, (state, action) => {
    const error = action.payload;
    return { ...state, loggedIn: false, loginFailed: true, status: AuthStatus.INIT, error };
  }),
  on(LoginFail, (state, action) => {
    const error = action.payload?.error?.data?.output?.payload;
    return { ...state, loginFailed: true, status: AuthStatus.INIT, error };
  }),
  on(SetInitialLoginState, (state, action) => {
    // console.log('SetInitialLoginState', action.payload);
    // console.log(JSON.stringify(action.payload.token));
    const { token, user } = action.payload;
    return {
      ...state,
      user,
      activeDomain: token.activeDomain,
      roles: token.roles,
      privileges: token.privileges,
      loginFailed: false,
      loggedIn: true,
      loggedInWithToken: false,
      status: AuthStatus.INIT,
    };
  }),

  on(RetrieveContextFromStorageSuccess, (state, action) => {
    return {
      ...state,
      loggedIn: true,
    };
  }),

  ///////////////////////////////////////////////////////////////////////////////////////
  on(Register, (state, action) => {
    return { ...state, registerRequestFailed: undefined, status: AuthStatus.IN_PROGRESS, user: action.payload };
  }),
  on(RegisterSuccess, (state, action) => {
    return {
      ...state,
      registerRequestFailed: false,
      loggedIn: false,
      loggedInWithToken: false,
      status: AuthStatus.INIT,
    };
  }),
  on(RegisterExistingUser, (state, action) => {
    return { ...state, registerRequestFailed: undefined, status: AuthStatus.IN_PROGRESS, user: action.payload };
  }),
  on(RegisterExistingUserSuccess, (state, action) => {
    return {
      ...state,
      registerRequestFailed: false,
      loggedIn: false,
      loggedInWithToken: false,
      status: AuthStatus.INIT,
    };
  }),
  // on(LoginSuccess, (state, action) => {
  //   return {
  //     ...state,
  //   };
  // }),
  on(LoginWithTokenSuccess, (state, action) => {
    return {
      ...state,
      activeDomain: action.payload.activeDomain,
      loggedInWithToken: true,
      status: AuthStatus.INIT,
    };
  }),
  on(LoginWithTokenFail, (state, action) => {
    return {
      ...state,
      loggedInWithToken: false,
      status: AuthStatus.INIT,
    };
  }),
  on(ConfirmEmailUpdateSuccess, (state, action) => {
    return {
      ...state,
      emailUpdateConfirmed: true,
      status: AuthStatus.INIT,
    };
  }),
  on(ConfirmEmailUpdateFail, (state, action) => {
    return {
      ...state,
      emailUpdateConfirmed: false,
      status: AuthStatus.INIT,
    };
  }),
  on(ConfirmRegisterSuccess, (state, action) => {
    return {
      ...state,
      registrationConfirmed: true,
      status: AuthStatus.INIT,
    };
  }),
  on(ConfirmRegisterFail, (state, action) => {
    return {
      ...state,
      registrationConfirmed: false,
      status: AuthStatus.INIT,
    };
  }),
  on(RecoverPassword, (state, action) => {
    return {
      ...state,
      passwordReset: undefined,
      status: AuthStatus.INIT,
    };
  }),
  on(RecoverPasswordSuccess, (state, action) => {
    return {
      ...state,
      passwordReset: true,
      status: AuthStatus.INIT,
    };
  }),
  on(RecoverPasswordFail, (state, action) => {
    return {
      ...state,
      passwordReset: false,
      status: AuthStatus.INIT,
    };
  }),
  on(RegisterFail, (state, action) => {
    return {
      ...state,
      registerRequestFailed: true,
      status: AuthStatus.INIT,
      error: action.payload?.error?.data?.output?.payload,
    };
  }),
  on(RegisterExistingUserFail, (state, action) => {
    return {
      ...state,
      registerRequestFailed: true,
      status: AuthStatus.INIT,
      error: action.payload?.error?.data?.output?.payload,
    };
  }),
  on(Logout, (state, action) => {
    return {
      ...initialState,
      loggedIn: false,
    };
  }),
  on(LogoutWithToken, (state, action) => {
    return {
      ...initialState,
      brandingCompanyInfo: state.brandingCompanyInfo,
    };
  }),
  on(RequestPasswordRecoveryEmailSuccess, (state, action) => {
    return {
      ...state,
      passwordResetRequestSent: true,
    };
  }),
  on(RequestPasswordRecoveryEmailFail, (state, action) => {
    let success = false;
    let result;
    if (action.payload.status === 404) {
      success = true;
      result = true;
    }
    return {
      ...state,
      passwordResetRequestSent: success,
      passwordResetRequestResult: result,
    };
  }),
  on(RequestPasswordRecoverySessionSuccess, (state, action) => {
    return {
      ...state,
      passwordResetRequestSent: true,
    };
  }),
  on(RequestPasswordRecoverySessionFail, (state, action) => {
    let success = false;
    let result;
    if (action.payload.status === 404) {
      success = true;
      result = true;
    }
    return {
      ...state,
      passwordResetRequestSent: success,
      passwordResetRequestResult: result,
    };
  }),
  on(ResetRequestPasswordRecovery, (state, action) => {
    return {
      ...state,
      passwordResetRequestSent: undefined,
      passwordResetRequestResult: undefined,
    };
  }),
  on(GetBrandingCompanyInfoSuccess, (state, action) => {
    const company = action.payload;
    return {
      ...state,
      brandingCompanyInfo: company,
    };
  }),
  on(CompanyDetailsChanged, (state, { companyDetails }) => {
    const brandingCompanyInfo = {
      ...state.brandingCompanyInfo,
      logo: companyDetails.logo,
      name: companyDetails.name,
      favicon: companyDetails.favicon,
      links: companyDetails.links,
    };
    return {
      ...state,
      brandingCompanyInfo,
    };
  }),
  on(ValidateUserInviteToken, (state, payload) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ValidateUserInviteTokenSuccess, (state, { payload }) => {
    return {
      ...state,
      userInviteToken: payload,
      loading: false,
    };
  }),
  on(ValidateUserInviteTokenFail, (state, payload) => {
    return {
      ...state,
      userInviteToken: null,
      loading: false,
    };
  }),
);

export function reducer(state: Auth | undefined, action: Action) {
  return authReducer(state, action);
}
