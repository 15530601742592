import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';

import * as AlertActions from './alert.actions';
import * as AlertFeature from './alert.reducer';

@Injectable()
export class AlertEffects {
  // addAlert$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AlertActions.addAlert),
  //     fetch({
  //       run: ({alert},) => {

  //         return AlertActions.addAlertSuccess({ alert });
  //       },
  //     })
  //   )
  // );

  constructor(private readonly actions$: Actions) {}
}
