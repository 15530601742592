import { IUser, IBusinessUser, Company, IRolePrivileges, ICompanyPrivilege } from 'libs/api/src/interfaces';

export interface Auth {
  loggedIn: boolean;
  loggedInWithToken?: boolean;
  user: Partial<IBusinessUser>;
  activeDomain: string;
  brandingCompanyInfo?: Company;
  roles: { companyId: string; companyName: string; role: string }[];
  privileges: ICompanyPrivilege;
  accessCompanies: { id:string, name:string }[];
  status: AuthStatus;
  loginFailed: boolean;
  registerRequestFailed?: boolean;
  emailUpdateConfirmed?: boolean;
  registrationConfirmed?: boolean;
  passwordReset?: boolean;
  passwordResetRequestSent?: boolean;
  passwordResetRequestResult?: boolean;
  error?: any;
  userInviteToken: any;
}

export interface UserResponse {
  data: Partial<IUser>;
}

export interface AuthState {
  readonly auth: Auth;
}

export enum AuthStatus {
  INIT = "INIT",
  IN_PROGRESS = "IN_PROGRESS"
}


export interface AuthUser {
  id: string;
  email: string;
}
// export interface AuthToken {
//   userId: string,
//   email: string,
//   activeDomain: string,
//   roles: { companyId: string; companyName: string; role: string }[];
//   privileges: IRolePrivileges[];
//   iat: any,
//   exp: any,
// }
