import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterEvent, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthFacade } from './+state/auth.facade';
import { RedirectUrlService } from 'libs/ui-auth/handlers/src/lib/redirect-url.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService  {
  canActivate$ = new Subject<boolean>();

  constructor(private authFacade: AuthFacade, private redirectService: RedirectUrlService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    this.authFacade.isLoggedIn$
    .pipe(
      filter((x) => typeof x === 'undefined'),
      take(1),
    )
    .subscribe((x) => {
      this.authFacade.retrieveContextFromStorage();
    });

    return this.authFacade.isLoggedIn$
    .pipe(
      filter(x => typeof x !== 'undefined'),
      map((isLoggedIn) => {
      if(!isLoggedIn){
        const urlTree = this.redirectService.clearContextAndGetRedirectUrlTree(state.url);
        return urlTree;
      }
      return true;
    }));
  }
}
