import { Injectable } from '@angular/core';

import { Company, IUser } from '@zc/api';
import { HttpApiService } from '@zc/api-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserResponse } from './+state/auth.interface';
import { AuthService, AuthUser, ILoginResponse } from './auth.service.interface';

@Injectable()
export class HttpAuthService implements AuthService {
  constructor(private apiService: HttpApiService) {}

  user(): Observable<UserResponse> { 
    return this.apiService.get(`/users/current`);
  }

  login(user: AuthUser): Observable<ILoginResponse> {
    return this.apiService.post('/login', user);
  }

  register(user: Partial<IUser>): Observable<UserResponse> {
    return this.apiService.post('/register', user);
  }

  registerExistingUser(user: Partial<IUser>): Observable<UserResponse> {
    return this.apiService.post('/register/existingUser', user);
  }

  confirmRegister(companyId: string): Observable<UserResponse> {
    return this.apiService.put('/auth/confirmRegister', { companyId });
  }

  confirmEmailUpdate(): Observable<any> {
    return this.apiService.put('/auth/confirmChangeEmail');
  }

  recoverPassword(userId: string, newPassword: string, companyId: string): Observable<any> {
    return this.apiService.put(`/login/${userId}/recover`, { newPassword, companyId });
  }

  requestPasswordRecoveryEmail(emailAddress: string, companyId: string): Observable<any> {
    return this.apiService.put(`/login/initRecoverPassword`, { emailAddress, companyId });
  }

  requestPasswordRecoverySession(emailAddress: string, sessionId: string): Observable<any> {
    return this.apiService.put(`/login/initRecoverPasswordSession`, { emailAddress, sessionId });
  }

  getCurrentCompanyInfo(cid: string): Observable<Company> {
    return this.apiService.get(`/currentdomain/${cid}`).pipe(map((x: any) => x.data));
  }

  changeDomain(activeDomain: string): Observable<ILoginResponse> {
    return this.apiService.put('/auth/changeActiveDomain', { newActiveDomain: activeDomain });
  }

  validateUserInviteCode(codeId: string, companyId: string) {
    return this.apiService
      .get<{ data: boolean }>(`/user-invite/validate/${codeId}/company/${companyId}`)
      .pipe(map((x) => x.data));
  }

  fetchCheckoutSessionInfo(sessionToken: string): Observable<any> {
    return this.apiService.get<{ data: any }>(`/login/checkoutSession/${sessionToken}`).pipe(map((x) => x.data));
  }

  checkIfUserIsVerified(email: string): Observable<any> {
    return this.apiService.get<{ data: any }>(`/login/isVerified/${email}`).pipe(map((x) => x.data));
  }
}
