import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IBusinessUser, IToken, userHasPrivilegeAtCompany, UserPermission } from '@zc/api';
import { LocalStorageKeys, LocalStorageService } from '@zc/ui-auth/handlers';
import JwtDecode from 'jwt-decode';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpAuthService } from '../auth.service';
import {
  ApplyToken,
  ConfirmEmailUpdate,
  ConfirmEmailUpdateFail,
  ConfirmEmailUpdateSuccess,
  ConfirmRegister,
  ConfirmRegisterFail,
  ConfirmRegisterSuccess,
  GetBrandingCompanyInfo,
  GetBrandingCompanyInfoFail,
  GetBrandingCompanyInfoSuccess,
  // GetUser,
  // GetUserFail,
  // GetUserSuccess,
  GetUserToken,
  GetUserTokenFail,
  GetUserTokenSuccess,
  Login,
  LoginFail,
  LoginSuccess,
  LoginWithToken,
  LoginWithTokenFail,
  LoginWithTokenSuccess,
  Logout,
  LogoutSuccess,
  LogoutWithToken,
  RecoverPassword,
  RecoverPasswordFail,
  RecoverPasswordSuccess,
  Register,
  RegisterFail,
  RegisterSuccess,
  RequestPasswordRecoveryEmail,
  RequestPasswordRecoveryEmailFail,
  RequestPasswordRecoveryEmailSuccess,
  RequestPasswordRecoverySession,
  RequestPasswordRecoverySessionFail,
  RequestPasswordRecoverySessionSuccess,
  // RetrieveActiveDomainFromStorageFail,
  // RetrieveActiveDomainFromStorageSuccess,
  RetrieveContextFromStorage,
  RetrieveContextFromStorageFail,
  // RetrieveContextFromStorageSuccess,
  ChangeActiveDomain,
  ChangeActiveDomainFail,
  ChangeActiveDomainSuccess,
  SetInitialLoginState,
  ValidateUserInviteToken,
  ValidateUserInviteTokenFail,
  ValidateUserInviteTokenSuccess,
  // SetActiveDomain,
  CompleteLogin,
  CompleteLoginFail,
  RegisterExistingUser,
  RegisterExistingUserSuccess,
  RegisterExistingUserFail,
} from './auth.actions';
import { UserResponse } from './auth.interface';
import { PureAbility } from '@casl/ability';
import { defineAbilityFor, getTestPrivilege } from '@zc/ability'; 
import { cloneDeep } from 'lodash';
import { AppAbility } from 'libs/ability/src/lib/ability';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private localStorageService: LocalStorageService,
    private authService: HttpAuthService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private ability: PureAbility,
  ) {}

  login$ = createEffect(() =>
    this.actions.pipe(
      ofType(Login),
      switchMap((action) => {
        // delete previous values
        this.localStorageService.removeItem(LocalStorageKeys.jwtToken);
        this.localStorageService.removeItem(LocalStorageKeys.activeDomain);
        this.ability.update([]); 
        // token will be applied on success
        const authUser = action.payload.authUser;
        const redirectPath = action.payload.redirectPath;
        return this.authService.login(authUser).pipe(
          map((response: any) => CompleteLogin({ jwt: response.data.token, redirectPath: redirectPath })),
          catchError((error) => of(LoginFail(error))),
        );
      }),
    ),
  );

  register$ = createEffect(() =>
    this.actions.pipe(
      ofType(Register),
      switchMap((item) =>
        this.authService.register(item.payload).pipe(
          map((user: UserResponse) => RegisterSuccess(user)),
          catchError((err) => of(RegisterFail(err))),
        ),
      ),
    ),
  );

  registerExistingUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(RegisterExistingUser),
      switchMap((item) =>
        this.authService.registerExistingUser(item.payload).pipe(
          map((user: UserResponse) => RegisterExistingUserSuccess(user)),
          catchError((err) => of(RegisterExistingUserFail(err))),
        ),
      ),
    ),
  );

  confirmRegister$ = createEffect(() =>
    this.actions.pipe(
      ofType(ConfirmRegister),
      switchMap(({ companyId }) =>
        this.authService.confirmRegister(companyId).pipe(
          map((user: UserResponse) => ConfirmRegisterSuccess(user)),
          catchError((err) => of(ConfirmRegisterFail(err))),
        ),
      ),
    ),
  );

  completeLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(CompleteLogin),
      switchMap(({ payload }: any) => {
        return this.setJwtAndQueryForUser(payload.jwt, true, payload.redirectPath);
      }),
      catchError((err) => {
        return of(CompleteLoginFail(err));
      }),
    ),
  );

  setInitialLoginState$ = createEffect(()=>
  this.actions.pipe(
    ofType(SetInitialLoginState),
    tap(({payload})=>{
      const user = cloneDeep(payload.user);
      user.privileges = payload.token.privileges;
      const ability = defineAbilityFor(user, payload.token.activeDomain, payload.hasLicense);
      this.ability.update(ability.rules);
      if(payload.redirectAfterLogin){
        const url = payload.redirectPath || '/admin/quotes';
        this.router.navigateByUrl(url);
      }
    }),
  ),
  {dispatch: false}
  )

  loginWithToken$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoginWithToken),
      switchMap(({ payload }) => {
        this.localStorageService.removeItem(LocalStorageKeys.activeDomain);
        this.localStorageService.removeItem(LocalStorageKeys.jwtToken);
        this.ability.update([]); 

        const decodedToken: IToken = JwtDecode(payload);

        return this.localStorageService.setItem(LocalStorageKeys.jwtTokenFromUrl, payload).pipe(
          map(() => LoginWithTokenSuccess(decodedToken)),
          catchError((err) => of(LoginWithTokenFail(err))),
        );
      }),
    ),
  );

  confirmEmailUpdate = createEffect(() =>
    this.actions.pipe(
      ofType(ConfirmEmailUpdate),
      switchMap(() => {
        return this.authService.confirmEmailUpdate().pipe(
          map(() => ConfirmEmailUpdateSuccess()),
          catchError((err) => of(ConfirmEmailUpdateFail(err))),
        );
      }),
    ),
  );

  logout$ = createEffect(() =>
    this.actions.pipe(
      ofType(Logout),
      tap(({ redirect }) => {
        this.localStorageService.removeItem(LocalStorageKeys.jwtToken);
        this.localStorageService.removeItem(LocalStorageKeys.activeDomain);
        this.localStorageService.removeItem(LocalStorageKeys.brandingCompany);
        this.ability.update([]);
      }),
      map(({ redirect }) => {
        return LogoutSuccess(redirect);
      }),
    ),
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LogoutSuccess),
        tap(({ redirect }) => {
          const path = this.router.url.split('?')[0].trim();

          if (path && path !== '/' && !path.startsWith('/auth/login')) {
            if (redirect) {
              this.router.navigate(['/'], { queryParams: { redirect }, queryParamsHandling: 'merge' });
            } else {
              this.router.navigateByUrl('/');
            }
          }
        }),
      ),
    { dispatch: false },
  );

  logoutWithToken$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LogoutWithToken),
        tap(() => {
          this.localStorageService.removeItem(LocalStorageKeys.jwtTokenFromUrl);
          this.localStorageService.removeItem(LocalStorageKeys.activeDomain);
          this.ability.update([]);
        }),
      ),
    { dispatch: false },
  );
  changeActiveDomain$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeActiveDomain),
      switchMap(({ payload }) => {
        return this.authService.changeDomain(payload.activeDomain).pipe(
          map((user: any) => ChangeActiveDomainSuccess({ token: user.data.token, redirectPath: payload.redirectPath })),
          catchError((err) => of(ChangeActiveDomainFail(err))),
        );
      }),
    ),
  );

  changeActiveDomainSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeActiveDomainSuccess),
      switchMap(({ payload }: any) => {
        return this.setJwtAndQueryForUser(payload.token, false, '');
      }),
      catchError((err) => {
        console.log(err);
        return of(ChangeActiveDomainFail(err));
      }),
    ),
  );

  retrieveContextFromStorage$ = createEffect(() =>
    this.actions.pipe(
      ofType(RetrieveContextFromStorage),
      switchMap((action: any) => {
        const jwt = this.localStorageService.getItem(LocalStorageKeys.jwtToken);
        return this.setJwtAndQueryForUser(jwt, false, '');
      }),
      catchError((err) => {
        return of(CompleteLoginFail(err));
      }),
    ),
  );

  retrieveContextFromStorageFail$ = createEffect(() =>
    this.actions.pipe(
      ofType(RetrieveContextFromStorageFail),
      map(() => Logout()),
    ),
  );

  recoverPassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecoverPassword),
      switchMap(({ payload }) => {
        return this.localStorageService.getItemObs(LocalStorageKeys.jwtTokenFromUrl).pipe(
          switchMap((token) => {
            this.localStorageService.setItem(LocalStorageKeys.jwtToken, token); // Set token in local storage for api calls
            const decodedToken: IToken = JwtDecode(token);
            return this.authService.recoverPassword(decodedToken.userId, payload.newPassword, payload.companyId).pipe(
              map((result) => RecoverPasswordSuccess(result)),
              catchError((err) => of(RecoverPasswordFail(err))),
            );
          }),
          catchError((err) => of(RecoverPasswordFail(err))),
        );
      }),
    ),
  );

  requestRecoverPasswordEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(RequestPasswordRecoveryEmail),
      switchMap(({ payload }) => {
        return this.authService.requestPasswordRecoveryEmail(payload.emailAddress, payload.companyId).pipe(
          map((result) => RequestPasswordRecoveryEmailSuccess(result)),
          catchError((err) => of(RequestPasswordRecoveryEmailFail(err))),
        );
      }),
      catchError((err) => of(RequestPasswordRecoveryEmailFail(err))),
    ),
  );

  requestRecoverPasswordSession$ = createEffect(() =>
    this.actions.pipe(
      ofType(RequestPasswordRecoverySession),
      switchMap(({ payload }) => {
        return this.authService.requestPasswordRecoverySession(payload.emailAddress, payload.sessionId).pipe(
          map((result) => {
            this.localStorageService.setItem(LocalStorageKeys.jwtTokenFromUrl, result.data.token);
            this.localStorageService.setItem(LocalStorageKeys.jwtToken, result.data.token);
            return RequestPasswordRecoverySessionSuccess(result);
          }),
          catchError((err) => of(RequestPasswordRecoverySessionFail(err))),
        );
      }),
      catchError((err) => of(RequestPasswordRecoverySessionFail(err))),
    ),
  );

  getBrandingCompanyInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetBrandingCompanyInfo),
      switchMap(() => {
        const currentParams = { ...(this.actRoute.snapshot.queryParamMap as any).params };

        // check for companyId in querystring
        let cid = '';
        if (currentParams.cid) {
          cid = currentParams.cid;
          this.localStorageService.setItem(LocalStorageKeys.brandingCompany, cid);
        } else {
          // check for branding company in local storage
          cid = this.localStorageService.getItem(LocalStorageKeys.brandingCompany) || '';
        }

        return this.authService.getCurrentCompanyInfo(cid).pipe(
          map((result) => GetBrandingCompanyInfoSuccess(result)),
          catchError((err) => of(GetBrandingCompanyInfoFail(err))),
        );
      }),
      catchError((err) => of(GetBrandingCompanyInfoFail(err))),
    ),
  );

  getUserToken$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetUserToken),
      switchMap((action) => {
        const activeDomain = this.localStorageService.getItem(LocalStorageKeys.activeDomain);
        return this.authService.changeDomain(activeDomain).pipe(
          map((result: any) => GetUserTokenSuccess(result.data.token)),
          catchError((error) => of(GetUserTokenFail(error))),
        );
      }),
    ),
  );

  getUserTokenSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetUserTokenSuccess),
      map(({ payload }: any) => {
        this.localStorageService.setItem(LocalStorageKeys.jwtToken, payload);
        const decodedToken: IToken = JwtDecode(payload);
        return ApplyToken(decodedToken);
      }),
    ),
  );

  validateUserInvite$ = createEffect(() =>
    this.actions.pipe(
      ofType(ValidateUserInviteToken),
      switchMap(({ tokenId, companyId }) =>
        this.authService.validateUserInviteCode(tokenId, companyId).pipe(
          map((isValid: boolean) => ValidateUserInviteTokenSuccess(isValid)),
          catchError((err) => of(ValidateUserInviteTokenFail(err))),
        ),
      ),
    ),
  );

  setJwtAndQueryForUser(jwt: string, redirectAfterLogin: boolean, redirectPath: string): Observable<any> {
    this.localStorageService.setItem(LocalStorageKeys.jwtToken, jwt);

    const decodedToken: IToken = JwtDecode(jwt);
    this.localStorageService.setItem(LocalStorageKeys.activeDomain, decodedToken.activeDomain);
    this.localStorageService.setItem(LocalStorageKeys.brandingCompany, decodedToken.activeDomain);

    //const userPrivileges = decodedToken.privileges;
    let hasSubscr = false;
    let canPurchaseSubscr = false;

    hasSubscr = userHasPrivilegeAtCompany(decodedToken, decodedToken.activeDomain, UserPermission.shouldcost);
    canPurchaseSubscr = userHasPrivilegeAtCompany(decodedToken, decodedToken.activeDomain, UserPermission.shouldcost);
    

    redirectPath = hasSubscr || !canPurchaseSubscr ? redirectPath : '/admin/licenses';

    return this.authService.user().pipe(
      map((response) => {
        return SetInitialLoginState({ token: decodedToken, user: response.data, hasLicense: hasSubscr, redirectAfterLogin, redirectPath });
      }),
    );
  }
}
