import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AbilityService } from '@casl/angular';
import { PureAbility } from '@casl/ability';

import { DefaultCompanyId, IUser, UserPermission, UserRole } from '@zc/api';
import { LocalStorageService } from '@zc/ui-auth/handlers';
import { HttpAuthService } from '../auth.service';
import {
  ChangeActiveDomain,
  CompanyDetailsChanged,
  ConfirmEmailUpdate,
  ConfirmRegister,
  GetBrandingCompanyInfo,
  //GetUser,
  GetUserToken,
  Login,
  LoginWithToken,
  Logout,
  LogoutWithToken,
  RecoverPassword,
  Register,
  RegisterExistingUser,
  RequestPasswordRecoveryEmail,
  RequestPasswordRecoverySession,
  ResetRequestPasswordRecovery,
  RetrieveContextFromStorage,
  ValidateUserInviteToken,
} from './auth.actions';
import { AuthState } from './auth.interface';
import { authQuery } from './auth.selectors';

@Injectable()
export class AuthFacade {
  activeDomain$ = this.store.select(authQuery.getActiveDomain);
  
  status$ = this.store.select(authQuery.getStatus);

  // roles$ = this.store.select(authQuery.getRoles);

  //privileges$ = this.store.select(authQuery.getPrivileges);

  accessCompanies$ = this.store.select(authQuery.getAccessCompanies);

  // userHasRoleAtActiveCompany$ = combineLatest([this.roles$, this.activeDomain$]).pipe(
  //   map(([rs, d]) => {
  //     return (roleName: string) =>
  //       !!rs.find((r) => (r.companyId === d || r.companyId === DefaultCompanyId) && r.role === roleName);
  //   }),
  // );

  // userHasPrivilegesAtActiveCompany$ = combineLatest([this.privileges$, this.activeDomain$]).pipe(
  //   filter(([r, d]) => !!r && d?.length > 0),
  //   map(([r, d]) => {
  //     return (privilegeName: UserPermission) =>
  //       ((r as any).companyId === d || (r as any).companyId == DefaultCompanyId) && 
  //       (r as any).privileges
  //         .filter((r1) => r1.companyId === d || r1.companyId === DefaultCompanyId)
  //         .flatMap((r2) => r2.privileges)
  //         .find((p) => p?.name === privilegeName);
  //   }),
  // );

  // isCompanyUser$ = this.userHasRoleAtActiveCompany$.pipe(
  //   map(
  //     (hasPermission: any) =>
  //       hasPermission(UserRole.sales_person) || hasPermission(UserRole.sales_manager) || hasPermission(UserRole.admin),
  //   ),
  // );

  // hasLicense$ = this.userHasRoleAtActiveCompany$.pipe(
  //   map((hasPermission: any) => hasPermission(UserRole.base) || hasPermission(UserRole.shouldcost_beta_company)),
  // );

  // canCreateQuote$ = this.userHasRoleAtActiveCompany$.pipe(
  //   map((hasPermission: any) => hasPermission(UserRole.base) || hasPermission(UserRole.sales_manager)),
  // );

  user$ = this.store.select(authQuery.getUser);

  loginFailed$ = this.store.select(authQuery.getLoginFailed);

  registerRequestFailed$ = this.store.select(authQuery.getRegisterRequestFailed);

  isLoggedIn$ = this.store.select(authQuery.getLoggedIn);

  isLoggedInWithToken$ = this.store.select(authQuery.getLoggedInWthToken);

  emailUpdateConfirmed$ = this.store.select(authQuery.getEmailUpdateConfirmed);

  registrationConfirmed$ = this.store.select(authQuery.getRegistrationConfirmed);

  passwordReset$ = this.store.select(authQuery.getPasswordReset);

  passwordResetRequestSent$ = this.store.select(authQuery.getPasswordResetRequestSent);

  getPasswordResetRequestResult$ = this.store.select(authQuery.getPasswordResetRequestResult);

  userInviteToken$ = this.store.select(authQuery.getUserInviteToken);

  brandingCompanyInfo$ = this.store.select(authQuery.getBrandingCompanyInfo);

  constructor(
    private store: Store<AuthState>,
    private storage: LocalStorageService,
    private authService: HttpAuthService,
  ) {}

  ////////////////////////////////////////////////////////////////
  // login, active domain state related....

  login(email: string, password: string, redirectPath: string) {
    this.store.dispatch(Login({ authUser: { email, password }, redirectPath }));
  }

  logout(redirect?: string) {
    this.store.dispatch(Logout(redirect));
  }
  changeActiveDomain(companyId: string, redirectPath?: string) {
    this.store.dispatch(ChangeActiveDomain({ activeDomain: companyId, redirectPath }));
  }

  retrieveContextFromStorage() {
    this.store.dispatch(RetrieveContextFromStorage());
  }

  getBrandingCompany() {
    this.store.dispatch(GetBrandingCompanyInfo());
  }

  handleUpdatedCompanyDetails(companyDetails: any) {
    this.store.dispatch(CompanyDetailsChanged(companyDetails));
  }

  ////////////////////////////////////////////////////////////////////////////////
  // registration, password recovery related...

  loginWithToken(token: string) {
    this.store.dispatch(LoginWithToken(token));
  }

  logoutWithToken() {
    this.store.dispatch(LogoutWithToken());
  }

  register(user: Partial<IUser>) {
    this.store.dispatch(Register(user));
  }

  registerExistingUser(user: Partial<IUser>) {
    this.store.dispatch(RegisterExistingUser(user));
  }

  confirmRegistration(companyId: string) {
    this.store.dispatch(ConfirmRegister(companyId));
  }

  getUserToken() {
    this.store.dispatch(GetUserToken());
  }

  validateUserInvite(tokenId: string, companyId: string) {
    this.store.dispatch(ValidateUserInviteToken({ tokenId, companyId }));
  }

  recoverPassword(newPassword: string, companyId: string) {
    this.store.dispatch(RecoverPassword({ newPassword, companyId }));
  }

  requestPasswordRecoveryEmail(emailAddress: string, companyId: string) {
    this.store.dispatch(RequestPasswordRecoveryEmail({ emailAddress, companyId }));
  }

  requestPasswordRecoverySession(emailAddress: string, sessionId: string) {
    this.store.dispatch(RequestPasswordRecoverySession({ emailAddress, sessionId }));
  }

  resetRequestPasswordRecovery() {
    this.store.dispatch(ResetRequestPasswordRecovery());
  }

  confirmEmailUpdate() {
    this.store.dispatch(ConfirmEmailUpdate());
  }

  fetchCheckoutSessionInfo(sessionToken: string) {
    return this.authService.fetchCheckoutSessionInfo(sessionToken);
  }

  checkIfUserIsVerified(email: string) {
    return this.authService.checkIfUserIsVerified(email);
  }
}
