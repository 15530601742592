import { IUserInfo } from 'libs/api/src/interfaces';
import { IRolePrivileges } from '../interfaces/role-privileges';
import { IUserRole } from '../interfaces/user-role';

export enum UserRole {
  customer = 'customer',
  sales_person = 'sales person',
  sales_manager = 'sales manager',
  admin = 'admin',
  account_admin = 'account admin',
  super_admin = 'super admin',

  base = 'base',
  // client_interface = 'client interface',
  // modules = 'modules',
  // milling_machines = 'milling machines',
  // turning_machines = 'turning machines',
  // afr_solidworks = 'afr solidworks',

  shouldcost = 'shouldcost',
  shouldcost_lite = 'lite',
  shouldcost_basic = 'basic',
  shouldcost_premium = 'premium',

  shouldcost_beta_company = 'beta admin',
  shouldcost_beta_client = 'beta client',

  blocked = 'blocked',
}

export enum UserPermission {
  shouldcost = 'shouldcost',
  Company = 'Company',  
  SuperAdmin = 'super admin',
  Admin = 'admin',
  AccountAdmin = 'account admin',
  Licenses = 'Licenses',
  // Machine = 'machines', // commenting out because it doesn't get used and it will make the transition to CASL abilities easier
}

export const UserRoleHierarchy = {
  [UserRole.admin]: 1,
  [UserRole.account_admin]: 1,
  [UserRole.shouldcost_beta_company]: 1,
  [UserRole.sales_manager]: 2,
  [UserRole.sales_person]: 3,
  [UserRole.customer]: 4, 
  [UserRole.shouldcost_beta_client]: 4,
}

// const RolePermissions = [
//     { role: UserRole.sales_person, permission: Permissions.Company },
//     { role: UserRole.sales_manager, permission: Permissions.Company },
//     { role: UserRole.admin, permission: Permissions.Admin },
//     { role: UserRole.account_admin, permission: Permissions.AccountAdmin },
//     { role: UserRole.sales_manager, permission: Permissions.Licenses },
//     { role: UserRole.admin, permission: Permissions.Licenses },
//     { role: UserRole.account_admin, permission: Permissions.Licenses },
// ];

const rolesByPermission = {
  admin: [UserRole.admin],
  Company: [UserRole.admin, UserRole.sales_person, UserRole.sales_manager, UserRole.base, UserRole.shouldcost, /*UserRole.shouldcost_beta_client*/],
  blocked: [UserRole.blocked],
};

export const companiesWhereUserHasPermission = (
  user: Pick<IUserInfo, 'privileges' | 'roles'>,
  permission: UserPermission,
) => {
  // if (user.privileges && user.privileges.length > 0) {
  //   const companiesWithPermission = user.privileges
  //     .filter(r => r.privileges && r.privileges.find(p => p.name === permission))
  //     .map(role => role.companyId);
  //   return companiesWithPermission;
  // }

  if(user.privileges){
    return userHasPrivilegeAtCompany(user, user.privileges.companyId, permission) ? [user.privileges.companyId] : [];
  }

  const rolesToSearch = rolesByPermission[permission] || [];
  return user?.roles?.filter(r => rolesToSearch.findIndex(s => s === r.role) >= 0).map(r => r.companyId);
};

export const userHasRolesAtCompany = (user: Pick<IUserInfo, 'roles'>, companyId: string) => {
  const rolesToSearch = rolesByPermission.Company;
  return (
    user?.roles?.filter(r => rolesToSearch.findIndex(s => s === r.role) >= 0 && r.companyId === companyId).length > 0
  );
};

export const userHasRolesAtCompanies = (user: Pick<IUserInfo, 'roles'>, roles:UserRole[] = []) => {
  
  // const rolesToSkip = rolesByPermission.blocked;
  // return user?.roles
  //   ?.filter(r => rolesToSkip.findIndex(s => s === r.role) < 0)
  //   .map(r => r.companyId)
  //   .filter((v, i, a) => a.indexOf(v) === i) || [];
  
  const rolesToSkip = rolesByPermission.blocked;
  const companiesToSkip = user?.roles
    ?.filter(r => rolesToSkip.findIndex(s => s === r.role) >= 0)
    .map(r => r.companyId)
    .filter((v, i, a) => a.indexOf(v) === i) || [];

  return user?.roles
    ?.filter(r => !roles || roles.length === 0 || (roles.indexOf(r.role) >= 0))
    .map(r => r.companyId)
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter(c => companiesToSkip.indexOf(c) < 0) || [];
};

// export const privilegesAtCompany = (user: Pick<IUserInfo, 'privileges'>, companyId: string) => {
//   const userPermissions = user?.privileges
//     ?.filter(p => p.companyId === companyId && !!p.privileges)
//     .map(p => p.privileges)
//     .reduce((allPermissions, newPermissions) => allPermissions.concat(newPermissions), []) || [];
//   return userPermissions;
// };

export const permissionsAtCompany = (user: Pick<IUserInfo, 'privileges'>, companyId: string) => {  
  //return privilegesAtCompany(user, companyId).map(p => p.name);
  if(user.privileges.companyId !== companyId){
    return [];
  }
  const privs = user.privileges.privileges.filter(p => {
    const privName = (p as any).subject;
    return Object.values(UserPermission).findIndex(k => k === privName) >= 0;
  })
  .map((p:any)=>p.subject); 
  return privs;
};

export const userHasPrivilegeAtCompany = ( 
  user: Pick<IUserInfo, 'privileges'>,
  companyId: string,
  permission: UserPermission,
) => {
  //return privilegesAtCompany(user, companyId).findIndex(p => p.name === permission) >= 0;
  const privs:any[] = permissionsAtCompany(user, companyId);
  return privs.findIndex(p => p === permission) >= 0;
};

export const userHasPrivilegesAtCompany = (user: Pick<IUserInfo, 'privileges'>, companyId: string) => {  
  // return privilegesAtCompany(user, companyId).length > 0;
  const privs:any[] = permissionsAtCompany(user, companyId);
  return privs.length > 0;
};

// export const userHasCompanyPrivilege = (userPrivileges: IRolePrivileges[], companyId: string) => {
//   return (
//     userPrivileges.findIndex(
//       x => x.privileges.findIndex(p => p.name === UserPermission.Company) >= 0 && x.companyId === companyId,
//     ) >= 0
//   );
// };
