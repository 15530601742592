import {
  createMongoAbility,
  ForcedSubject,
  CreateAbility,
  MongoAbility,
  AbilityBuilder,
  AbilityTuple,
  MongoQuery,
  SubjectType,
  RawRule
} from '@casl/ability';
import { IBusinessUser, ISubjectActionPrivilege, QuoteStatus } from '@zc/api';
import { cloneDeep } from 'lodash';

export enum AbilityAction {
  create = 'create',
  read = 'read',
  edit = 'edit',
  delete = 'delete'
}

export enum QuoteAbilityAction {
  cancel = 'quote_cancel',
  createOwn = 'quote_createOwn',
  duplicate = 'quote_duplicate',
}

// define all the abilities
const actions = [AbilityAction.create, AbilityAction.read, AbilityAction.edit, AbilityAction.delete, QuoteAbilityAction.cancel, QuoteAbilityAction.createOwn];
const subjects: SubjectType[] = ['all'];  

//export type AppAbilityAction = typeof actions;

type AppAbilities = [
  typeof actions[number],
  typeof subjects[number] | ForcedSubject<Exclude<typeof subjects[number], 'all'>>,
];

export type AppAbility = MongoAbility<AppAbilities, MongoQuery>;
export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>;


// flattens SC privileges structure into a single array of RawRules
export function getRuleFromSCPrivilege(priv: ISubjectActionPrivilege, activeDomain: string, parentSubject = '') : RawRule[]{
  //console.log(JSON.stringify(priv));
  
  const currSubject = (parentSubject ? parentSubject + '.' : '') + priv.subject;
  return priv.actions.map(action => (
    { 
      action,
      subject: currSubject,
      conditions: currSubject === 'Quote' || currSubject.startsWith('Quote.') ? {seller: activeDomain} : undefined  // we need to confirm the active domain is the seller, to secure against the possibility that a user logs in to their own company and then uses their JWT to hit the backend routes and modify another company's quotes 
    } as RawRule))
    .concat(
      (priv.subPrivileges?.length ? priv.subPrivileges?.flatMap(sp => getRuleFromSCPrivilege(sp, activeDomain, currSubject)) : 
      [])
    )
}


export function combinePrivileges(targetPrivs: ISubjectActionPrivilege[], sourcePrivs: ISubjectActionPrivilege[]){
  //const resultPrivs = priv1.map(p => cloneDeep(p));
  for(let sourcePriv of sourcePrivs){
    
    const targetPriv = targetPrivs.find(p => p.subject === sourcePriv.subject);
    
    if(!(targetPriv)) {
      targetPrivs.push(cloneDeep(sourcePriv));
    }
    else{
      targetPriv.actions = targetPriv.actions.concat(sourcePriv.actions.filter(a => targetPriv.actions.indexOf(a) === -1));
      if(sourcePriv.subPrivileges?.length){
        if(!targetPriv.subPrivileges){
          targetPriv.subPrivileges = [];
        }
        combinePrivileges(targetPriv.subPrivileges, sourcePriv.subPrivileges);
      }
    }
  }
}

function createCannot(cannot, actions:string[], subject, condition: MongoQuery<any> | undefined = undefined){  
    for(let action of actions){
      cannot(action, subject, condition);
    }
}
function createCan(can, actions:string[], subject, condition: MongoQuery<any> | undefined = undefined){  
  for(let action of actions){
    can(action, subject, condition);
  }
}  

function defineRulesForQuote(user: Partial<IBusinessUser>, activeDomain:string, companyHasLicenses: boolean, can, cannot){

  // other CANS that are more a function of the quote's relationship with the active domain than the user's role in the active domain...
  // RFQ
  can(AbilityAction.read, 'Quote.requestQuote', {'buyer': {'$in':[user.id, activeDomain]}, 'status.label': {'$in': [QuoteStatus.OPEN.label, QuoteStatus.DRAFT.label]}});

  // Quote wide rules ______________

  if(!companyHasLicenses){
    createCannot(cannot, [AbilityAction.create, AbilityAction.edit, AbilityAction.delete], 'Quote');
  }
  createCannot(cannot, [AbilityAction.create, AbilityAction.read, AbilityAction.edit, AbilityAction.delete], 'Quote', {'seller': {'$ne': activeDomain}, 'buyer': {'$nin': [activeDomain, user.id]}});
  can(AbilityAction.delete, 'Quote', {'buyer': user.id});
  createCannot(cannot, [AbilityAction.edit], 'Quote', {'status.label': {'$eq': QuoteStatus.NO_QUOTE.label}});
  //createCannot(cannot, [AbilityAction.edit], 'Quote', {'status.label': {'$eq': QuoteStatus.NO_QUOTE.label}});
  createCannot(cannot, [AbilityAction.edit, AbilityAction.delete], 'Quote', {'status.progress': {'$gte': QuoteStatus.QUOTED.progress}}); 
  createCannot(cannot, [AbilityAction.edit, AbilityAction.delete], 'Quote', {'seller': {'$ne': activeDomain}, 'status.progress': {'$gte': QuoteStatus.RFQ.progress}});  
  
  // cancel Quote - buyer can cancel - but NO ONE can cancel if the quote isn't in draft status
  can(QuoteAbilityAction.cancel, 'Quote', {'buyer': user.id});
  cannot(QuoteAbilityAction.cancel, 'Quote', {'status.label': {'$ne': QuoteStatus.DRAFT.label}})

  // duplicate Quote - buyer can duplicate
  can(QuoteAbilityAction.duplicate, 'Quote', {'buyer': user.id});

  // Quote field specific "cannots" (and cans that are unrelated to user's role in the company)___________________________
  // status
  cannot(AbilityAction.edit, 'Quote.status', {'seller': {'$ne': activeDomain}});  // can edit status if seller company user rights and not No Quoted
  
  // status.acceptReject 
  can(AbilityAction.edit, 'Quote.status.acceptReject', {'buyer': user.id});  // can edit status if seller company user rights and not No Quoted
  
  // buyer
  createCannot(cannot, [AbilityAction.create, AbilityAction.read, AbilityAction.edit, AbilityAction.delete], 'Quote.buyer', {'seller': {'$ne': activeDomain}});
  createCannot(cannot, [AbilityAction.create, AbilityAction.edit, AbilityAction.delete], 'Quote.buyer', {'buyer': {'$nin':[null, undefined, user.id]}});

  // print  (hate mixing cans and cannot.  but couldn't figure out another way to say "users with company rights to the selling company can print a quote before it is QUOTED")
  //        these rules get applied on top of the Quote.print privileges that come from user.privileges
  cannot(AbilityAction.read, 'Quote.print', {'seller': {'$ne': activeDomain}});
  can(AbilityAction.read, 'Quote.print', {'status.progress': {'$gte': QuoteStatus.QUOTED.progress}});
  
  // prices
  cannot(AbilityAction.edit, 'Quote.prices', {'seller': {'$ne': activeDomain}});
  cannot(AbilityAction.edit, 'Quote.prices', {'status.progress': {'$gte': QuoteStatus.QUOTED.progress}});
  
  // costs
  cannot(AbilityAction.edit, 'Quote.costs', {'seller': {'$ne': activeDomain}});
  cannot(AbilityAction.edit, 'Quote.costs', {'status.progress': {'$gte': QuoteStatus.QUOTED.progress}});

  // leadtime
  cannot(AbilityAction.edit, 'Quote.leadtime', {'seller': {'$ne': activeDomain}});
  
  // builder
  cannot(AbilityAction.read, 'Quote.builder', {'seller': {'$ne': activeDomain}});

  // view new quote tutorial for seller
  cannot(AbilityAction.read, 'Quote.sellerTutorial', {'seller': {'$ne': activeDomain}});

  // files
  createCan(can, [AbilityAction.create, AbilityAction.read, AbilityAction.edit, AbilityAction.delete], 'Quote.files', {'buyer': user.id});
  
  // alternate quantities (customer)
  createCan(can, [AbilityAction.create, AbilityAction.read, AbilityAction.edit, AbilityAction.delete], 'Quote.alternateQuantities', {'status.label':{'$in':[QuoteStatus.OPEN.label, QuoteStatus.DRAFT.label]}, 'buyer': {'$in': [activeDomain, user.id]}})

  // add part (customer)
  createCan(can, [AbilityAction.create, AbilityAction.read], 'Quote.part', {'status.label':{'$in':[QuoteStatus.OPEN.label, QuoteStatus.DRAFT.label]}, 'buyer': user.id});

  // part name (customer)
  createCan(can, [AbilityAction.read, AbilityAction.edit], 'Quote.part.name', {'status.label':{'$in':[QuoteStatus.OPEN.label, QuoteStatus.DRAFT.label]}, 'buyer': user.id});

  
}


// define abilities for user
export function defineAbilityFor(user: Partial<IBusinessUser>, activeDomain:string, companyHasLicenses: boolean): AppAbility {
  //console.log(JSON.stringify(user.privileges));
  if(user.privileges?.companyId !== activeDomain){
    return new AbilityBuilder(createAppAbility).build();  // return empty
  }
  
  const { can, cannot, rules, build } = new AbilityBuilder(createAppAbility);

  // ROLE DERIVED CANS _______________________________________________________________________________________________________________ 
  // define the "cans" that come from the user's privileges which are combined from user's roles and their licenses
  // ______________________________________________________________________________________________________________________
  const privs = user.privileges?.privileges;
  // assuming that user.privilege contains privileges for the current activeDoman only
  if(privs?.length){
    const allRules: any[] = privs.flatMap((p) => getRuleFromSCPrivilege(p, activeDomain));
    rules.push(...allRules);
  }

  // QUOTE SPECIFIC ___________________________________________________________________________________________________
  // define the cannots (and some cans) that are determined by the user's relationship with the specific quote
  defineRulesForQuote(user, activeDomain, companyHasLicenses, can, cannot);
  
  const appAbility = build();

  //console.log(JSON.stringify(rules));
  return appAbility;
}


