import { QuoteStatus } from '@zc/api';
import { cloneDeep } from 'lodash';

export let testCid1 = '64749bf803f95983bac409a6';
export let testCid2 = '64749bf803f95983bac409a7';

export const baseUser1 = {
  id: '64749c0303f95983bac40a47',
  userId: '64749c0303f95983bac40a47',
  name: 'xm',
  email: 'xm@mailinator.com',
  roles: [],
};

export const baseUser2 = {
  id: 'a3149c07f4f95983bac40a96',
  userId: 'a3149c07f4f95983bac40a96',
  name: 'xm1',
  email: 'xm1@mailinator.com',
  roles: [],
};

export const baseQuote: any = {
  name: 'test quote',
  status: { ...QuoteStatus.OPEN },
};

export const test_base_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
      {
        "subject" : "base",
        "actions" : [
            "create",
            "read",
            "edit",
            "delete"
        ]
    },
    {
        "subject" : "Quotes",
        "actions" : [
            "read"
        ]
    },
    {
        "subject" : "Quote",
        "actions" : [
            "create",
            "read",
            "quote_createOwn"
        ]
    }
  ],
};

export const test_shouldcost_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
    {
      subject: 'shouldcost',
      actions: ['create', 'read', 'edit', 'delete'],
    },
    { subject: 'Quotes', actions: ['read'] },
    {
      subject: 'Quote',
      actions: ['read', 'create', 'createOwn'],
    }
  ],
};

// export const test_customer_privilege = {
//   companyName: 'Test Company',
//   companyId: testCid1,
//   privileges: [
//     {
//       subject: 'customer',
//       actions: ['create', 'read', 'edit', 'delete'],
//     },
//     {
//       subject: 'Quote',
//       actions: ['read'],
//     },
//     {
//       subject: 'User',
//       actions: ['read', 'edit'],
//     },
//   ],
// };

export const test_sales_person_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
    {
      "subject": "sales person",
      "actions": ["create", "read", "edit", "delete"],
    },
    {
      "subject": "Quote",
      "actions": ["create", "read", "edit"],
      "subPrivileges": [
        { "subject": "status", "actions": ["create", "read", "edit"], "subPrivileges":[{"subject":"acceptReject", "actions":["read", "edit"]}]},
        { "subject": "buyer", "actions": ["read"] },
        { "subject": "print", "actions": ["read"] },
        { "subject": "leadtime", "actions": ["edit"] },
        { "subject": "builder", "actions": ["read"] },
        { "subject": "sellerTutorial", "actions": ["read"] },
        { "subject": "files", "actions": ["create", "read", "edit", "delete"]},
        { "subject": "costs", "actions": ["create", "read", "edit", "delete"] }
      ],
    }, 
    {
        "subject" : "Company",
        "actions" : [ 
            "read" 
        ]
    },
    {
        "subject" : "Licenses",
        "actions" : [ 
            "read"
        ]
    }, 
  ],
};

export const test_sales_manager_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
    {
      "subject": "sales manager",
      "actions": ["create", "read", "edit", "delete"],
    },
    {
      "subject": "Quote",
      "actions": ["create", "read", "edit"],
      "subPrivileges": [
        { "subject": "buyer", "actions": ["create", "read", "edit"] },
        { "subject": "status", "actions": ["create", "read", "edit"], "subPrivileges":[{"subject":"acceptReject", "actions":["read", "edit"]}]},
        { "subject": "prices", "actions": ["edit"] },
        { "subject": "print", "actions": ["read"] },
        { "subject": "leadtime", "actions": ["edit"] },
        { "subject": "builder", "actions": ["read"] },
        { "subject": "sellerTutorial", "actions": ["read"] },
        { "subject": "files", "actions": ["create", "read", "edit", "delete"]},
        { "subject": "costs", "actions": ["create", "read", "edit", "delete"] }
      ],
    }, 
    {
        "subject" : "Company",
        "actions" : [ 
            "read", 
            "edit", 
        ],
        "subPrivileges": [
          {"subject": "settings", "actions":["create", "read", "edit"]}
        ]
    },
    {
        "subject" : "Licenses",
        "actions" : [ 
            "create", 
            "read", 
            "edit", 
            "delete"
        ]
    }, 
  ],
};

export const test_admin_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
    {
        "subject" : "admin",
        "actions" : [
            "create",
            "read",
            "edit",
            "delete"
        ]
    },
    {
        "subject" : "Licenses",
        "actions" : [
            "create",
            "read",
            "edit",
            "delete"
        ]
    },
    {
        "subject" : "Quote",
        "actions" : [
            "create",
            "read",
            "edit",
            "delete",
            "quote_cancel",
            "quote_duplicate"
        ],
        "subPrivileges" : [
            {
                "subject" : "buyer",
                "actions" : [
                    "create",
                    "read",
                    "edit"
                ]
            },
            {
                "subject" : "status",
                "actions" : [
                    "create",
                    "read",
                    "edit"
                ],
                "subPrivileges" : [
                    {
                        "subject" : "acceptReject",
                        "actions" : [
                            "read",
                            "edit"
                        ]
                    }
                ]
            },
            {
                "subject" : "prices",
                "actions" : [
                    "edit"
                ]
            },
            {
                "subject" : "print",
                "actions" : [
                    "read"
                ]
            },
            {
                "subject" : "leadtime",
                "actions" : [
                    "edit"
                ]
            },
            {
                "subject" : "builder",
                "actions" : [
                    "read"
                ]
            },
            {
                "subject" : "sellerTutorial",
                "actions" : [
                    "read"
                ]
            },
            {
                "subject" : "files",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "costs",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "alternateQuantities",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "part",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ],
                "subPrivileges" : [
                    {
                        "subject" : "name",
                        "actions" : [
                            "create",
                            "read",
                            "edit",
                            "delete"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "subject" : "Company",
        "actions" : [
            "read",
            "edit"
        ],
        "subPrivileges" : [
            {
                "subject" : "settings",
                "actions" : [
                    "create",
                    "read",
                    "edit"
                ]
            },
            {
                "subject" : "details",
                "actions" : [
                    "read",
                    "edit"
                ]
            },
            {
                "subject" : "laborCodes",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "machines",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "operations",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "routines",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "contacts",
                "actions" : [
                    "create",
                    "read",
                    "edit",
                    "delete"
                ]
            },
            {
                "subject" : "importFromME",
                "actions" : [
                    "edit"
                ]
            }
        ]
    },
    {
        "subject" : "Users",
        "actions" : [
            "create",
            "read",
            "edit",
            "delete"
        ]
    },
    {
        "subject" : "TestRoutes",
        "actions" : [
            "edit"
        ]
    }
],
};

export const test_account_admin_privilege = {
  companyName: 'Test Company',
  companyId: testCid1,
  privileges: [
        {
            "subject" : "account admin",
            "actions" : [ 
                "create", 
                "read", 
                "edit", 
                "delete"
            ]
        }, 
        {
            "subject" : "Licenses",
            "actions" : [ 
                "create", 
                "read", 
                "edit", 
                "delete"
            ]
        }, 
        { 
          "subject": "Users",
          "actions":[
            "create",
            "read",
            "edit",
            "delete"
          ]
        },
        {
            "subject" : "Company",
            "actions" : [ 
                "read", 
                "edit"
            ],
            "subPrivileges" : [ 
                {
                    "subject" : "settings",
                    "actions" : [ 
                        "create", 
                        "read", 
                        "edit"
                    ]
                }
            ]
        }
    ]
}

export function getTestPrivilege(role: string, companyId: string) {
  switch (role) {
    case 'base':
      return {
        ...cloneDeep(test_base_privilege),
        companyId,
      };
    case 'customer':
      return {
        ...cloneDeep(test_base_privilege),
        companyId,
      };
    case 'sales person':
      return {
        ...cloneDeep(test_sales_person_privilege),
        companyId,
      };
    case 'sales manager':
      return {
        ...cloneDeep(test_sales_manager_privilege),
        companyId,
      };
    case 'admin':
      return {
        ...cloneDeep(test_admin_privilege),
        companyId,
      };
    case 'account admin':
      return {
        ...cloneDeep(test_account_admin_privilege),
        companyId,
      };
  }
  throw Error(`${role} not defined.`);
}
