
export interface ICompanyPrivilege {
    companyId: string;
    privileges: ISubjectActionPrivilege[]
}

export interface ISubjectActionPrivilege {
    subject: string,
    actions: string[],
    subPrivileges?: ISubjectActionPrivilege[]
}